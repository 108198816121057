
export default function Footer () {

    return (
        <>
        <footer className="bg-[#1F1F1F] flex w-full justify-center">
        <p className="text-white my-3">© 2024 copyright all right reserved</p>
        </footer>
        </>
    )
}